import React, { useState, useEffect } from "react";
import { Form, Button, Card } from "react-bootstrap";
import Moment from "react-moment";
import "moment-timezone";
import { latestCryptoNews, SearchEndpoint } from "../api/ApiUtils";
import CustomPagination from "./pagination";
import Search from "./search";
import {
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
} from "react-share";
import ScrollButton from "./htmlElements/scrollButton";
import HorizontalBanner from "../components/adsBanners/horizontal";
import Sidebar from "./Sidebar";

function News(props) {
  const [cryptoNews, setCryptoNews] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [recordsCount, setRecordsCount] = useState(0);
  const [limit, setLimit] = useState(50);
  const [Pages, setPages] = useState(0);

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await latestCryptoNews(pageNo, limit);
      setCryptoNews(response.data.data.news_feeds.news_feeds);
      setRecordsCount(response.data.data.news_feeds.total_news_feeds);
      setPages(response.data.data.news_feeds.pages);
    }
    fetchMyAPI();
  }, []);

  async function pageHandler(pageNo) {
    setPageNo(pageNo);
    const response = await latestCryptoNews(pageNo, limit);
    setCryptoNews(response.data.data.news_feeds.news_feeds);
    setRecordsCount(response.data.data.news_feeds.total_news_feeds);
    setPages(response.data.data.news_feeds.pages);
  }

  async function searchHandler(pageNo, limit, model, term) {
    setPageNo(pageNo);
    const response = await SearchEndpoint(model, term, limit, pageNo);
    setCryptoNews(response.data.data.news_feeds);
    setRecordsCount(response.data.data.total_news_feeds);
    setPages(response.data.data.pages);
  }

  return (
    <div className="mt-5">
      <div className="row">
        <div className="col">
          <span>
            {`Showing ${pageNo * limit + 1}-${
              (pageNo + 1) * limit
            } of ${recordsCount}`}{" "}
          </span>
        </div>
        <div className="col">
          <div className="text-center">
            <CustomPagination
              pageNo={pageNo}
              pages={Math.ceil(recordsCount / limit)}
              pageHandler={pageHandler}
            />
          </div>
        </div>
        <div className="col">
          <div className="text-center">
            <Search
              pageNo={pageNo}
              limit={limit}
              model="news"
              searchHandler={searchHandler}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-8" style={{ background: "cream" }}>
          {cryptoNews?.map((newsItem, i) => {
            return (
              <div key={i} style={{ marginBottom: "10px" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    {" "}
                    <img
                      src={newsItem.news_room_logo_url || ""}
                      alt="logo"
                      style={{
                        height: "50px",
                        width: "50px",
                        marginRight: "10px",
                      }}
                    />
                    <strong>{newsItem.news_room_name}</strong> -{" "}
                    <a
                      href={`/newsitem/${newsItem.url_slug}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        fontWeight: "bold",
                        color: "#007bff",
                        textDecoration: "none",
                      }}
                    >
                      {newsItem.title}{" "}
                    </a>
                    <br />
                    <small>
                      <Moment fromNow>{newsItem.createdAt}</Moment>
                    </small>
                  </div>
                  <div className="d-flex align-items-center">
                    <TwitterShareButton
                      title={`${newsItem.title} #CryptoCurrencyNews`}
                      via={`coinjupiter`}
                      url={`https://www.coinjupiter.com/newsitem/${newsItem.url_slug}`}
                      children={<TwitterIcon size={25} round={true} />}
                    />
                    <RedditShareButton
                      title={newsItem.title}
                      url={`https://www.coinjupiter.com/newsitem/${newsItem.url_slug}`}
                      children={<RedditIcon size={25} round={true} />}
                    />
                  </div>
                </div>
                <hr />
              </div>
            );
          })}
        </div>

        <div className="col-sm-block col-lg-4" style={{ background: "white" }}>
          <Sidebar />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <span>
            {`Showing ${pageNo * limit + 1}-${
              (pageNo + 1) * limit
            } of ${recordsCount}`}{" "}
          </span>
        </div>
        <div className="col">
          <div className="text-center">
            <CustomPagination
              pageNo={pageNo}
              pages={Math.ceil(recordsCount / limit)}
              pageHandler={pageHandler}
            />
          </div>
        </div>
      </div>

      <ScrollButton />
    </div>
  );
}

export default News;
